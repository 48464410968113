<template>
  <v-container>
    <v-breadcrumbs :items="items"></v-breadcrumbs>
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="400">
      <v-card-title>Group</v-card-title>
      <div class="d-flex align-center justify-space-around">
        <div class="form-content text-center">
          <v-list>
            <v-list-item 
              v-for="(item, i) in menus"
              :key="i"
              class="menu-item"
              @click="clickMenu(item)"
            >
              <v-list-item-content>
                <v-list-item-title
                  class="text-left"
                  v-text="item.text"
                ></v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon v-if="item.count"
                >{{ item.count }} Users</v-list-item-icon
              >
              <v-list-item-icon>
                <v-icon>mdi-chevron-right</v-icon></v-list-item-icon
              >
            </v-list-item>
          </v-list>
          <div class="text-right">
            <v-btn
              :to="{ name: 'PageTestSchoolRegister' }"
              rounded
              color="primary"
              dark
            >
              Register School
            </v-btn>
          </div>
        </div>
      </div>
    </v-card>
    <v-card class="mx-auto pa-3 mt-3" max-width="800" outlined min-height="400">
      <v-card-title>School Applications</v-card-title>
      <div class="d-flex align-center justify-space-around">
        <div class="form-content text-left">
          <v-list>
            <v-chip  class="ma-2" x-small> Pending Approval</v-chip>
            <v-list-item class="menu-item">
              <v-list-item-content>
                <v-list-item-title class="text-left"
                  >School C</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>mdi-chevron-right</v-icon></v-list-item-icon
              >
            </v-list-item>
          </v-list>
        </div>
      </div>
    </v-card>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    step: 1,
    name: null,
    mobile: null,
    otp: null,
    confirmPassword: null,
    password: null,
    show1: false,
    show: false,
    isClickSocial: false,
    menus: [
      { text: "My Family", route: "PageTestFamilyDashboard", count: 5 },
      { text: "Private Lession", route: null, count: 0 },
      { text: "School A", route: "PageTestSchoolDashboard", count: 0 },
      { text: "School B", route: "PageTestSchoolDashboard", count: 0 },
    ],
    items: [
      {
        text: "Dashboard",
        disabled: false,
        to: { name: "PageTestSchoolDashboard" },
      },
      {
        text: "Groups",
        disabled: true,
        to: { name: "PageTestGroup" },
      },
    ],
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    clickMenu(item) {
      this.$router.push({ name: item.route });
    },
  },
};
</script>
<style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.form-content {
  width: 600px !important;
}
</style>